import Cookies from 'universal-cookie';
import {Navigate} from 'react-router-dom';
var forge = require('node-forge');
export default function Page() {
    const cookies = new Cookies();
    const authToken = cookies.get("auth")
    if (authToken == null) {
    function generateRandomString() {
        let randomString = '';
        const randomNumber = Math.floor(Math.random() * 10);
    
        for (let i = 0; i < 20 + randomNumber; i++) {
            randomString += String.fromCharCode(33 + Math.floor(Math.random() * 94));
        }
    
        return randomString;
    }
    const randomString = generateRandomString();
    var md = forge.md.sha256.create();
    md.update(randomString);
    const hash = md.digest().toHex();
    cookies.set('state', hash, { path: '/' });
    return (
      <>
        {window.location.replace(`https://login.microsoftonline.com/713d3c09-b1b4-46a6-93dc-cd6b86269ddd/oauth2/v2.0/authorize?client_id=f6cf81b8-0825-43a1-a6c4-ad6bd1aa4ab2&scope=user.read&response_type=code&redirect_uri=https%3A%2F%2Fmeunw.com%2Fcallback&state=${hash}`)}
      </>
    )}
    else {
      return (
        <>
          <Navigate to='/' />
        </>
      )
    }
  }