import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  Bars3Icon,
  ChartPieIcon,
  CursorArrowRaysIcon,
  FingerPrintIcon,
  SquaresPlusIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid'
import logo from './myUNW.png'
import Cookies from 'universal-cookie';
import {Navigate} from 'react-router-dom';
import axios from 'axios';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [displayName, setDisplayName] = useState('loading...');
  const cookies = new Cookies();
  const authToken = cookies.get("auth")
  if (authToken) {
    axios
      .get(`https://api.meunw.com/auth`, {
        headers: {
          "Authorization": authToken
        }
      })
      .then((response) => {
        if (response.status === 200) {
          console.log("Success", response);
          setDisplayName(response.data.displayName);
        }
      }).catch((error) => {console.log("Failed", error);
      cookies.remove("auth");
      {window.location.replace(`https://meunw.com/authprep`)}
    });
  return ( 
  <>
    <header className="bg-unw-purple">
      <div className="navbar bg-unw-purple">
  <div className="flex-1">
          <a href="#" className="-m-1.5 p-1.5">
            <span className="sr-only">meUNW</span>
            <img className="h-10" src={logo} alt="" />
          </a>
  </div>
  <div className="flex-none">
    <div className="dropdown dropdown-end pr-2">
      <label tabIndex={0} className="btn btn-ghost">
        {displayName}
      </label>
      <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-amber-500 text-black rounded-box w-52 ">
        <li>
          <a className="justify-between">
            Profile
            <span className="badge bg-unw-purple">New</span>
          </a>
        </li>
        <li><a>Settings</a></li>
        <li><a>Logout</a></li>
      </ul>
    </div>
  </div>
</div>
    </header>

    {/* <div className="drawer">
  <input id="my-drawer" type="checkbox" className="drawer-toggle" />
  <div className="drawer-content">

    <label htmlFor="my-drawer" className="btn btn-primary drawer-button">Open drawer</label>
  </div> 
  <div className="drawer-side">
    <label htmlFor="my-drawer" className="drawer-overlay"></label>
    <ul className="menu p-4 w-80 h-full bg-base-200 text-base-content">

      <li><a>Sidebar Item 1</a></li>
      <li><a>Sidebar Item 2</a></li>
      
    </ul>
  </div>
</div> */}
<div className='m-5'>
<div className="flex w-full sm:columns-1">
  <div className="grid flex-grow card bg-base-200 rounded-box ">
    <div className='flex w-full h-4 bg-green-500 rounded-t-lg'></div>
  <p className='ml-4 text-lg font-semibold mt-4'>Facilities</p><div className="divider"></div>
  <a className="link link-info ml-4" href='http://facilitiesrequest.unwsp.edu/'>Submit a Facilities Request</a>
  <div className='flex w-50 columns-3'>
    <div className='w-25'>
      <div className="stat-title ml-4 mt-8">Office</div>
      <div className="ml-6 mt-2 text-semibold text-lg">P2010</div>
    </div>
    <div className='w-50'>
      <div className="stat-title ml-4 mt-8">Email</div>
      <div className="ml-6 mt-2 text-semibold text-lg">facilitieshelp@unwsp.edu</div>
    </div>
    <div className='w-30'>
      <div className="stat-title ml-4 mt-8">Phone</div>
      <div className="ml-6 mt-2 text-semibold text-lg">651-286-7700</div>
    </div>
  </div>
  <p className='ml-4 mb-8'></p></div>
  <div className="divider divider-horizontal"></div>

  <div className="grid flex-grow card bg-base-200 rounded-box "><div className='flex w-full h-4 bg-blue-500 rounded-t-lg'></div>
  <p className='ml-4 text-lg font-semibold mt-4'>Event Services</p><div className="divider"></div>
  <a className="link link-info ml-4" href='https://form.jotform.com/43433130987153'>Reserve a Room</a>
  <div className='flex w-50 columns-3'>
    <div className='w-25'>
      <div className="stat-title ml-4 mt-8">Office</div>
      <div className="ml-6 mt-2 text-semibold text-lg">F2101</div>
    </div>
    <div className='w-50'>
      <div className="stat-title ml-4 mt-8">Email</div>
      <div className="ml-6 mt-2 text-semibold text-lg">reservearoom@unwsp.edu</div>
    </div>
    <div className='w-30'>
      <div className="stat-title ml-4 mt-8">Phone</div>
      <div className="ml-6 mt-2 text-semibold text-lg">651-631-5116</div>
    </div>
  </div>
  <p className='ml-4 mb-8'></p></div>

  <div className="divider divider-horizontal"></div>
  
  <div className="grid flex-grow card bg-base-200 rounded-box "><div className='flex w-full h-4 bg-rose-500 rounded-t-lg'></div>
  <p className='ml-4 text-lg font-semibold mt-4'>Purchasing</p><div className="divider"></div>
  <a className="link link-info ml-4" href='https://unw.atlassian.net/servicedesk/customer/portal/13'>Purchasing Jira Portal</a>
  <div className='flex w-50 columns-3'>
    <div className='w-25'>
      <div className="stat-title ml-4 mt-8">Office</div>
      <div className="ml-6 mt-2 text-semibold text-lg">R1005</div>
    </div>
    <div className='w-50'>
      <div className="stat-title ml-4 mt-8">Email</div>
      <div className="ml-6 mt-2 text-semibold text-lg">PurchasingInbox@unwsp.edu</div>
    </div>
    <div className='w-30'>
      <div className="stat-title ml-4 mt-8">Phone</div>
      <div className="ml-6 mt-2 text-semibold text-lg">651-631-5684</div>
    </div>
  </div>
  <p className='ml-4 mb-8'></p>
  </div>



</div>
</div>

<div className='m-5'>
<div className="flex w-full sm:columns-1">
  <div className="grid flex-grow card bg-base-200 rounded-box ">
    <div className='flex w-full h-4 bg-cyan-500 rounded-t-lg'></div>
  <p className='ml-4 text-lg font-semibold mt-4'>O365</p><div className="divider"></div>
  <a className="link link-info ml-4" href='https://unweagles.sharepoint.com/_layouts/15/sharepoint.aspx'>Sharepoint Dashboard</a>
  <a className="link link-info ml-4" href='https://unweagles-my.sharepoint.com/'>Onedrive</a>
  <a className="link link-info ml-4" href='https://admin.office.com/'>Office Admin</a>
  <p className='ml-4 mb-8 w-2/6'></p></div>
  <div className="divider divider-horizontal"></div>

  <div className="grid flex-grow card bg-base-200 rounded-box "><div className='flex w-full h-4 bg-amber-500 rounded-t-lg'></div>
  <p className='ml-4 text-lg font-semibold mt-4'>Banner</p><div className="divider"></div>
  <a className="link link-info ml-4" href='https://banner.unwsp.edu/'>Admin Pages</a>
  <a className="link link-info ml-4" href='https://ssb.unwsp.edu/ssomanager/saml/login?relayState=/c/auth/SSB'>Self Service</a>
  <a className="link link-info ml-4" href='https://banner.unwsp.edu/links/'>Banner Links</a>

  <p className='ml-4 mb-8 w-2/6'></p></div>

  <div className="divider divider-horizontal"></div>
  
  <div className="grid flex-grow card bg-base-200 rounded-box "><div className='flex w-full h-4 bg-rose-500 rounded-t-lg'></div>
  <p className='ml-4 text-lg font-semibold mt-4'>Other Links</p><div className="divider"></div>
  <a className="link link-info ml-4" href='https://dynamicforms.ngwebsolutions.com/casAuthentication.ashx?InstID=e82a92cb-4381-4221-a0c2-1238b79781c3&targetUrl=https%3a%2f%2fdynamicforms.ngwebsolutions.com'>Dynamic Forms</a>
  <a className="link link-info ml-4" href='https://unw.teamwork.com/launchpad/saml/start?SAMLAfterLoginURL=https%3A%2F%2Funw.teamwork.com%2F%23%2F'>Teamwork</a>
  <a className="link link-info ml-4" href='https://banner.unwsp.edu/links/'>Banner Links</a>




  <p className='ml-4 mb-8 w-2/6'></p>
  </div>
</div>
</div>
</>)}
else {
  return ( <Navigate to='/authprep' /> )
}
}