import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Homepage from './App.js';
import AuthPrep from './authPrep.js';
import Callback from './callback.js';

function App() {
return (
	<Router>
	<Routes>
		<Route exact path='/' element={<Homepage />} />
        <Route exact path='/authprep' element={<AuthPrep />} />
        <Route exact path='/callback' element={<Callback />} />
	</Routes>
	</Router>
);
}

export default App;