import React from "react"
import Cookies from 'universal-cookie';
import {Navigate} from 'react-router-dom';
import axios from 'axios';
export default function Page() {
    const cookies = new Cookies();
    const queryParameters = new URLSearchParams(window.location.search)
    const code = queryParameters.get("code")
    const state = queryParameters.get("state")
    const originalState = cookies.get("state")
    if (originalState) {
    console.log("----Return Hash----")
    console.log('--------------------------------')
    console.log(originalState)
    console.log(state)
    console.log(code)
    if (originalState === state) {
      cookies.remove("state")
      console.log("Attempting Token Put")
      axios
      .put(`https://api.meunw.com/token`, {
        body: code
      })
      .then((response) => {
        if (response.status === 200) {
          cookies.set("auth", response.data, { path: '/' });
          console.log("Attempting Redirect")
          window.location.replace(`https://meunw.com/`)
        }
      });
    }
    if (state!== originalState) {
      return (
        <Navigate to="/404" />
      )
    }
  }
}